import { compile, PathFunction, pathToRegexp } from "path-to-regexp";
import { BulkChangeSchemes } from "src/components/routes/Imports/ImportsBulkChange/utils";

export enum Route {
  AboutYou = "AboutYou",
  AcceptTerms = "AcceptTerms",
  AccountStatus = "AccountStatus",
  AccountClosure = "AccountClosure",
  AchUpliftDetails = "AchUpliftDetails",
  Admin = "Admin",
  AppAccessTokens = "AppAccessTokens",
  BankAccountData = "BankAccountData",
  BankDetails = "BankDetails",
  BankDetailsSelect = "BankDetailsSelect",
  BankStatementName = "BankStatementName",
  BillingDetails = "BillingDetails",
  BillingDetailsCallback = "BillingDetailsCallback",
  Blocklist = "Blocklist",
  BlocklistCreateBlock = "BlocklistCreateBlock",
  BusinessCategory = "BusinessCategory",
  BusinessDetails = "BusinessDetails",
  BusinessDirectors = "BusinessDirectors",
  BusinessOwners = "BusinessOwners",
  Brand = "Brand",
  BrandLogoAndColours = "BrandLogoAndColours",
  BrandContactDetails = "BrandContactDetails",
  BrandBankReference = "BrandBankReference",
  BrandCustomPaymentExperience = "BrandCustomPaymentExperience",
  Chargebacks = "Chargebacks",
  ChargebacksSelectRelatedChargebacks = "ChargebacksSelectRelatedChargebacks",
  ChargebackUploadEvidence = "ChargebackUploadEvidence",
  CompanySettings = "CompanySettings",
  CompanyPlanAndAddons = "CompanyPlanAndAddons",
  ConfigurationPresets = "ConfigurationPresets",
  CompanyConfigurations = "CompanyConfigurations",
  ConnectedApps = "ConnectedApps",
  ContactDetails = "ContactDetails",
  CreateAccount = "CreateAccount",
  CreatePaymentProviderAccount = "CreatePaymentProviderAccount",
  CreditorBankAccounts = "CreditorBankAccounts",
  CreditorDetails = "CreditorDetails",
  Creditors = "Creditors",
  Customer = "Customer",
  CustomerBankAccount = "CustomerBankAccount",
  CustomerBankAccounts = "CustomerBankAccounts",
  Customers = "Customers",
  CustomersCreate = "CustomersCreate",
  CustomBankStatement = "CustomBankStatement",
  CustomBankStatementMandate = "CustomBankStatementMandate",
  CustomBankStatementMandateCallback = "CustomBankStatementMandateCallback",
  Developers = "Developers",
  DevelopersAccessTokenEdit = "DevelopersAccessTokenEdit",
  DevelopersWebhooks = "DevelopersWebhooks",
  DevelopersPartnersFees = "DevelopersPartnersFees",
  Events = "Events",
  EventsDetails = "EventsDetails",
  ForcedPasswordReset = "ForcedPasswordReset",
  ForgotPassword = "ForgotPassword",
  GetStarted = "GetStarted",
  Home = "Home",
  ImportBulkCustomers = "ImportBulkCustomers",
  ImportBulkMandatesBulkChangeAch = "ImportBulkMandatesBulkChangeAch",
  ImportBulkMandatesBulkChangeAutogiro = "ImportBulkMandatesBulkChangeAutogiro",
  ImportBulkMandatesBulkChangeBacs = "ImportBulkMandatesBulkChangeBacs",
  ImportBulkMandatesBulkChangeBecs = "ImportBulkMandatesBulkChangeBecs",
  ImportBulkMandatesBulkChangeBecsNz = "ImportBulkMandatesBulkChangeBecsNz",
  ImportBulkMandatesBulkChangeBetalingsservice = "ImportBulkMandatesBulkChangeBetalingsservice",
  ImportBulkMandatesBulkChangePad = "ImportBulkMandatesBulkChangePad",
  ImportBulkMandatesBulkChangeSepa = "ImportBulkMandatesBulkChangeSepa",
  ImportBulkMandatesMandateMigrationPayTo = "ImportBulkMandatesMandateMigrationPayTo",
  ImportBulkPayments = "ImportBulkPayments",
  Import = "Import",
  Imports = "Imports",
  ImportsPreview = "ImportsPreview",
  ImportsBulkChangeEntry = "ImportsBulkChangeEntry",
  ImportsBulkChangeFlow = "ImportsBulkChangeFlow",
  ImportsBulkChangeRequest = "ImportsBulkChangeRequest",
  InstalmentSchedule = "InstalmentSchedule",
  InstalmentScheduleCreate = "InstalmentScheduleCreate",
  InstalmentScheduleCreateSuccess = "InstalmentScheduleCreateSuccess",
  InstalmentScheduleCustomerSearch = "InstalmentScheduleCustomerSearch",
  InstalmentScheduleSelection = "InstalmentScheduleSelection",
  InstalmentSchedules = "InstalmentSchedules",
  InstalmentScheduleShare = "InstalmentScheduleShare",
  IntelligentRetries = "IntelligentRetries",
  Intro = "Intro",
  LogoUpload = "LogoUpload",
  Mandate = "Mandate",
  Mandates = "Mandates",
  MFASetup = "MFASetup",
  MFASetupAuthenticator = "MFASetupAuthenticator",
  MFASetupSMS = "MFASetupSMS",
  Monitoring = "Monitoring",
  MoreBusinessDetails = "MoreBusinessDetails",
  NotFound = "NotFound",
  Onboarding = "Onboarding",
  OnboardingPackageSelection = "OnboardingPackageSelection",
  OnboardingWelcome = "OnboardingWelcome",
  OnboardingWelcomeConnect = "OnboardingWelcomeConnect",
  OneOffPaymentCreate = "OneOffPaymentCreate",
  OneOffPaymentCreateSuccess = "OneOffPaymentCreateSuccess",
  OneOffPaymentCustomerSearch = "OneOffPaymentCustomerSearch",
  OneOffPaymentPaylinksList = "OneOffPaymentPaylinksList",
  OneOffPaymentSelection = "OneOffPaymentSelection",
  PackageSelection = "PackageSelection",
  PackageSelectionEdit = "PackageSelectionEdit",
  PasswordEdit = "PasswordEdit",
  PaylinksCreate = "PaylinksCreate",
  PaylinkShare = "PaylinkShare",
  Payment = "Payment",
  PaymentCreate = "PaymentCreate",
  PaymentName = "PaymentName",
  PaymentRequest = "PaymentRequest",
  PaymentRequestEmail = "PaymentRequestEmail",
  PaymentRequestLink = "PaymentRequestLink",
  Payments = "Payments",
  PaymentSetting = "PaymentSetting",
  PaymentShare = "PaymentShare",
  PaymentVolumes = "PaymentVolumes",
  Payout = "Payout",
  Payouts = "Payouts",
  PayoutTransactions = "PayoutTransactions",
  PersonActingOnBehalfOf = "PersonActingOnBehalfOf",
  PublicKey = "PublicKey",
  RecurringPaymentCreate = "RecurringPaymentCreate",
  RecurringPaymentCreateSuccess = "RecurringPaymentCreateSuccess",
  RecurringPaymentCustomerSearch = "RecurringPaymentCustomerSearch",
  RecurringPaymentSelection = "RecurringPaymentSelection",
  Refer = "Refer",
  ReferralLink = "ReferralLink",
  Refund = "Refund",
  Refunds = "Refunds",
  RequestPayments = "RequestPayments",
  RiskSettings = "RiskSettings",
  RiskSettingsChange = "RiskSettingsChange",
  RouteTo = "RouteTo",
  Search = "Search",
  Settings = "Settings",
  Setup = "Setup",
  SetupPayments = "SetupPayments",
  SetupUser = "SetupUser",
  SetupUserVerify = "SetupUserVerify",
  SignIn = "SignIn",
  SignUp = "SignUp",
  SignUpExp = "SignUpExp",
  Subscription = "Subscription",
  SubscriptionPause = "SubscriptionPause",
  SubscriptionResume = "SubscriptionResume",
  Subscriptions = "Subscriptions",
  SubscriptionTemplate = "SubscriptionTemplate",
  SubscriptionTemplateCustomers = "SubscriptionTemplateCustomers",
  SubscriptionTemplateEdit = "SubscriptionTemplateEdit",
  SubscriptionTemplates = "SubscriptionTemplates",
  SubscriptionTemplatesCreate = "SubscriptionTemplatesCreate",
  SubscriptionTemplatesCustomerSearch = "SubscriptionTemplatesCustomerSearch",
  SubscriptionTemplateShare = "SubscriptionTemplateShare",
  SubscriptionTemplateShareAssignCustomers = "SubscriptionTemplateShareAssignCustomers",
  SubscriptionTemplatesSelection = "SubscriptionTemplatesSelection",
  SubscriptionTemplateManagePaymentSchedule = "SubscriptionTemplateManagePaymentSchedule",
  SubscriptionTemplateSchedulePause = "SubscriptionTemplateSchedulePause",
  SubscriptionTemplateScheduleResume = "SubscriptionTemplateScheduleResume",
  SuccessPlus = "SuccessPlus",
  SuccessPlusConfigure = "SuccessPlusConfigure",
  SuccessPlusReporting = "SuccessPlusReporting",
  Team = "Team",
  TeamUserEdit = "TeamUserEdit",
  TrusteeDetails = "TrusteeDetails",
  TrustOwners = "TrustOwners",
  PaymentProviderBranding = "PaymentProviderBranding",
  VerificationBanner = "VerificationBanner",
  Verified = "Verified",
  VerifyBank = "VerifyBank",
  VerifyBankDocument = "VerifyBankDocument",
  Welcome = "Welcome",
  WelcomePaymentType = "WelcomePaymentType",
  WelcomeUsageSelection = "WelcomeUsageSelection",
  ZendeskFormsInterceptor = "ZendeskFormsInterceptor",
}

export const rawPaths: Record<Route, string> = {
  [Route.AboutYou]: "/setup/about-you",
  [Route.AcceptTerms]: "/accept-terms",
  [Route.AccountStatus]: "/setup/account-status",
  [Route.AccountClosure]: "/company/settings/close",
  [Route.AchUpliftDetails]: "/setup/ach-uplift-details",
  [Route.Admin]: "/admin",
  [Route.AppAccessTokens]: "/app-access-tokens",
  [Route.BankAccountData]: "/bank-account-data",
  [Route.BankDetails]: "/setup/bank-details",
  [Route.BankDetailsSelect]: "/setup/bank-details-select",
  [Route.BankStatementName]: "/setup/bank-statement-name",
  [Route.BillingDetails]: "/setup/billing-details",
  [Route.BillingDetailsCallback]: "/setup/billing-details/callback",
  [Route.Blocklist]: "/protect-plus/blocklist",
  [Route.BlocklistCreateBlock]: "/protect-plus/blocklist/create-block",
  [Route.BusinessCategory]: "/setup/business-category",
  [Route.BusinessDetails]: "/setup/business-details",
  [Route.BusinessDirectors]: "/setup/business-directors",
  [Route.BusinessOwners]: "/setup/business-owners",
  [Route.Brand]: "/brand",
  [Route.BrandLogoAndColours]: "/brand/logo-and-colours",
  [Route.BrandContactDetails]: "/brand/contact-details",
  [Route.BrandBankReference]: "/brand/bank-reference",
  [Route.BrandCustomPaymentExperience]: "/brand/custom-payment-experience",
  [Route.Chargebacks]: "/chargebacks",
  [Route.ChargebacksSelectRelatedChargebacks]:
    "/chargebacks/:id/select-related-chargebacks",
  [Route.ChargebackUploadEvidence]: "/chargebacks/:id/upload-evidence",
  [Route.CompanyConfigurations]: "/company/configurations",
  [Route.CompanySettings]: "/company/settings",
  [Route.CompanyPlanAndAddons]: "/company/plan-and-addons",
  [Route.ConfigurationPresets]: "/success-plus/configuration-presets",
  [Route.ConnectedApps]: "/company/apps",
  [Route.ContactDetails]: "/setup/contact-details",
  [Route.CreateAccount]: "/create-account",
  [Route.CreatePaymentProviderAccount]: "/embed/create-account",
  [Route.CreditorBankAccounts]: "/creditor-bank-accounts/:id",
  [Route.CreditorDetails]: "/creditors/:id",
  [Route.Creditors]: "/creditors",
  [Route.Customer]: "/customers/:id",
  [Route.CustomerBankAccount]: "/customer-bank-accounts/:id",
  [Route.CustomerBankAccounts]: "/customer-bank-accounts",
  [Route.Customers]: "/customers",
  [Route.CustomersCreate]: "/customers/create/:id",
  [Route.CustomBankStatement]: "/custom-bank-statement/business-name",
  [Route.CustomBankStatementMandate]: "/custom-bank-statement/mandate",
  [Route.CustomBankStatementMandateCallback]:
    "/custom-bank-statement/mandate-callback",
  [Route.Developers]: "/developers",
  [Route.DevelopersAccessTokenEdit]: "/developers/access-tokens/:id/edit",
  [Route.DevelopersWebhooks]: "/developers/webhooks/:id",
  [Route.DevelopersPartnersFees]: "/developers/partners/fees",
  [Route.Events]: "/events",
  [Route.EventsDetails]: "/events/:id",
  [Route.ForcedPasswordReset]: "/password/forced-reset",
  [Route.ForgotPassword]: "/password/create",
  [Route.GetStarted]: "/get-started",
  [Route.Home]: "/",
  [Route.ImportBulkCustomers]: "/imports/create/customers",
  [Route.ImportBulkMandatesBulkChangeAch]:
    "/imports/create/mandates/bulk_change_ach",
  [Route.ImportBulkMandatesBulkChangeAutogiro]:
    "/imports/create/mandates/bulk_change_autogiro",
  [Route.ImportBulkMandatesBulkChangeBacs]:
    "/imports/create/mandates/bulk_change_bacs",
  [Route.ImportBulkMandatesBulkChangeBecs]:
    "/imports/create/mandates/bulk_change_becs",
  [Route.ImportBulkMandatesBulkChangeBecsNz]:
    "/imports/create/mandates/bulk_change_becs_nz",
  [Route.ImportBulkMandatesBulkChangeBetalingsservice]:
    "/imports/create/mandates/bulk_change_betalingsservice",
  [Route.ImportBulkMandatesBulkChangePad]:
    "/imports/create/mandates/bulk_change_pad",
  [Route.ImportBulkMandatesBulkChangeSepa]:
    "/imports/create/mandates/bulk_change_sepa",
  [Route.ImportBulkMandatesMandateMigrationPayTo]:
    "/imports/create/mandates/mandate_migration_pay_to",
  [Route.ImportBulkPayments]: "/imports/create/payments",
  [Route.Import]: "/imports/:id",
  [Route.Imports]: "/imports",
  [Route.ImportsPreview]: "/imports-preview",
  [Route.ImportsBulkChangeEntry]: "/imports/bulk-change",
  [Route.ImportsBulkChangeFlow]: "/imports/bulk-change/:scheme",
  [Route.ImportsBulkChangeRequest]: "/imports/bulk-change/request",
  [Route.InstalmentSchedule]: "/instalment-schedules/:id",
  [Route.InstalmentScheduleCreate]: "/instalment-schedules/create",
  [Route.InstalmentScheduleCreateSuccess]:
    "/instalment-schedules/create-success",
  [Route.InstalmentScheduleCustomerSearch]:
    "/instalment-schedules/customer-search",
  [Route.InstalmentScheduleSelection]: "/instalment-schedules/selection",
  [Route.InstalmentSchedules]: "/instalment-schedules",
  [Route.InstalmentScheduleShare]: "/instalment-schedules/:id/share",
  [Route.IntelligentRetries]: "/success-plus/intelligent-retries",
  [Route.Intro]: "/intro",
  [Route.LogoUpload]: "/setup-payments/logo-upload",
  [Route.Mandate]: "/mandates/:id",
  [Route.Mandates]: "/mandates",
  [Route.MFASetup]: "/mfa-setup",
  [Route.MFASetupAuthenticator]: "/mfa-setup/authenticator",
  [Route.MFASetupSMS]: "/mfa-setup/sms",
  [Route.Monitoring]: "/protect-plus/monitoring",
  [Route.MoreBusinessDetails]: "/setup/more-business-details",
  [Route.NotFound]: "/404",
  [Route.Onboarding]: "/onboarding/:step?",
  [Route.OnboardingPackageSelection]: "/onboarding/package-selection",
  [Route.OnboardingWelcome]: "/onboarding/welcome",
  [Route.OnboardingWelcomeConnect]: "/onboarding/welcome-connect",
  [Route.OneOffPaymentCreate]: "/one-off-payment/create",
  [Route.OneOffPaymentCreateSuccess]: "/one-off-payment/create-success",
  [Route.OneOffPaymentCustomerSearch]: "/one-off-payment/customer-search",
  [Route.OneOffPaymentPaylinksList]: "/one-off-payment/paylinks-list",
  [Route.OneOffPaymentSelection]: "/one-off-payment/selection",
  [Route.PackageSelection]: "/setup/package-selection",
  [Route.PackageSelectionEdit]: "/package-selection/edit",
  [Route.PasswordEdit]: "/password/edit",
  [Route.PaylinksCreate]: "/payments/paylinks/create",
  [Route.PaylinkShare]: "/payments/paylinks/:id/share",
  [Route.Payment]: "/payments/:id",
  [Route.PaymentCreate]: "/setup-payments/create",
  [Route.PaymentName]: "/setup-payments/payment-name",
  [Route.PaymentRequest]: "/payment-request/form",
  [Route.PaymentRequestEmail]: "/payment-request/:id/email",
  [Route.PaymentRequestLink]: "/payment-request/:id/link",
  [Route.Payments]: "/payments",
  [Route.PaymentSetting]: "/setup-payments/setting",
  [Route.PaymentShare]: "/setup-payments/share",
  [Route.PaymentProviderBranding]: "/embed/branding/:creditorId",
  [Route.PaymentVolumes]: "/setup/payment-volumes",
  [Route.Payout]: "/payouts/:id",
  [Route.Payouts]: "/payouts",
  [Route.PayoutTransactions]: "/payouts/:id/transactions",
  [Route.PersonActingOnBehalfOf]: "/setup/person-acting-on-behalf-of",
  [Route.PublicKey]: "/developers/public-key",
  [Route.RecurringPaymentCreate]: "/recurring-payment/create",
  [Route.RecurringPaymentCreateSuccess]: "/recurring-payment/create-success",
  [Route.RecurringPaymentCustomerSearch]: "/recurring-payment/customer-search",
  [Route.RecurringPaymentSelection]: "/recurring-payment/selection",
  [Route.Refer]: "/refer",
  [Route.ReferralLink]: "/refer/:geo/:name/:code",
  [Route.Refund]: "/refunds/:id",
  [Route.Refunds]: "/refunds",
  [Route.RequestPayments]: "/request-payments",
  [Route.RiskSettings]: "/protect-plus/risk-settings",
  [Route.RiskSettingsChange]: "/protect-plus/risk-settings/edit",
  [Route.RouteTo]: "/routeto",
  [Route.Search]: "/search",
  [Route.Settings]: "/settings",
  [Route.Setup]: "/setup",
  [Route.SetupPayments]: "/setup-payments",
  [Route.SetupUser]: "/setup-user",
  [Route.SetupUserVerify]: "/setup-user/verify",
  [Route.SignIn]: "/sign-in",
  [Route.SignUp]: "/sign-up",
  [Route.SignUpExp]: "/sign-up-exp",
  [Route.Subscription]: "/subscriptions/:id",
  [Route.SubscriptionPause]: "/subscriptions/:id/pause",
  [Route.SubscriptionResume]: "/subscriptions/:id/resume",
  [Route.Subscriptions]: "/subscriptions",
  [Route.SubscriptionTemplate]: "/subscription-templates/:id",
  [Route.SubscriptionTemplateCustomers]:
    "/subscription-templates/:id/customers",
  [Route.SubscriptionTemplateEdit]: "/subscription-templates/:id/edit",
  [Route.SubscriptionTemplates]: "/subscription-templates",
  [Route.SubscriptionTemplatesCreate]: "/subscription-templates/create",
  [Route.SubscriptionTemplatesCustomerSearch]:
    "/subscription-templates/customer-search",
  [Route.SubscriptionTemplateShare]:
    "/subscription-templates/:id/customers/create",
  [Route.SubscriptionTemplateShareAssignCustomers]:
    "/subscription-templates/:id/customers/create/assign-customers",
  [Route.SubscriptionTemplatesSelection]: "subscription-templates/selection",
  [Route.SubscriptionTemplateManagePaymentSchedule]:
    "/subscription-templates/:id/manage-payment-schedule",
  [Route.SubscriptionTemplateSchedulePause]:
    "/subscription-templates/:id/manage-payment-schedule/schedule-pause",
  [Route.SubscriptionTemplateScheduleResume]:
    "/subscription-templates/:planId/manage-payment-schedule/:planSchedulerId/schedule-resume",
  [Route.SuccessPlus]: "/success-plus",
  [Route.SuccessPlusConfigure]: "/success-plus/configure",
  [Route.SuccessPlusReporting]: "/success-plus/reporting",
  [Route.Team]: "/company/team",
  [Route.TeamUserEdit]: "/company/team/users/:id/edit",
  [Route.TrusteeDetails]: "/setup/trustee-details",
  [Route.TrustOwners]: "/setup/trust-owners",
  [Route.VerificationBanner]: "/verification-banner",
  [Route.Verified]: "/setup/verified",
  [Route.VerifyBank]: "/setup/verify-bank",
  [Route.VerifyBankDocument]: "/setup/verify-bank-document",
  [Route.Welcome]: "/welcome",
  [Route.WelcomePaymentType]: "/welcome/payment-type",
  [Route.WelcomeUsageSelection]: "/welcome/usage-selection",
  [Route.ZendeskFormsInterceptor]: "/forms/zendesk/:formId",
};

const routes: Record<Route, PathFunction> = {} as Record<Route, PathFunction>;
for (const route in rawPaths) {
  routes[route as Route] = compile(rawPaths[route as Route]);
}

/**
 * External routes for the application that point to enterprise-dashboard. We do
 * a hard refresh when navigating to these pages in order to hit the load
 * balancer and load the enterprise-dashboard.
 */
const externalRoutes: ReadonlySet<Route> = new Set([
  Route.Admin,
  Route.CreditorBankAccounts,
  Route.CreditorDetails,
  Route.Creditors,
  Route.Developers,
  Route.DevelopersAccessTokenEdit,
  Route.DevelopersWebhooks,
  Route.DevelopersPartnersFees,
  Route.ImportBulkPayments,
  Route.Imports,
  Route.ImportBulkCustomers,
  Route.ImportBulkMandatesBulkChangeAch,
  Route.ImportBulkMandatesBulkChangeAutogiro,
  Route.ImportBulkMandatesBulkChangeBacs,
  Route.ImportBulkMandatesBulkChangeBecs,
  Route.ImportBulkMandatesBulkChangeBecsNz,
  Route.ImportBulkMandatesBulkChangeBetalingsservice,
  Route.ImportBulkMandatesBulkChangePad,
  Route.ImportBulkMandatesBulkChangeSepa,
  Route.ImportBulkMandatesMandateMigrationPayTo,
  Route.Onboarding,
  Route.OnboardingPackageSelection,
  Route.Welcome,
  Route.WelcomePaymentType,
  Route.WelcomeUsageSelection,
]);

/** An array of internal routes that can only be accessed when not logged in. */
export const PublicOnlyRoutes: string[] = [
  routes[Route.SignIn](),
  routes[Route.SignUp](),
  routes[Route.SignUpExp](),
  routes[Route.ForgotPassword](),
  routes[Route.ForcedPasswordReset](),
  routes[Route.PasswordEdit](),
  routes[Route.CreateAccount](),
  routes[Route.CreatePaymentProviderAccount](),
];

/** An array of dynamic internal routes that can only be accessed when not logged in. */
export const PublicOnlyDynamicRoutes: string[] = ["/refer/:geo/:name/:code"];

export const PublicOnlyRoutesWithoutSegment: string[] = [
  routes[Route.SignIn](),
  routes[Route.ForgotPassword](),
  routes[Route.PasswordEdit](),
];

export const MFASetupRoutes: string[] = [
  routes[Route.MFASetup](),
  routes[Route.MFASetupSMS](),
  routes[Route.MFASetupAuthenticator](),
];

/**
 * HOW TO ADD PAGES TO FULLSTORY
 * Follow the steps in this page:
 * https://gocardless.atlassian.net/wiki/spaces/Spark/pages/6469622611/Fullstory+how-tos#How-can-I-add-Fullstory-on-a-new-page%3F
 * Then add your route to the array below.
 */
export const FullStoryAllowedRoutes: (string | RegExp)[] = [
  routes[Route.Intro](),
  routes[Route.SetupPayments](),
  routes[Route.LogoUpload](),
  routes[Route.PaymentCreate](),
  routes[Route.PaymentName](),
  routes[Route.PaymentSetting](),
  routes[Route.PaymentShare](),
  new RegExp(routes[Route.SubscriptionTemplatesCreate]()),
  new RegExp(routes[Route.SubscriptionTemplateShare]({ id: "[A-Z0-9]+" })),
  routes[Route.BankDetailsSelect](),
  routes[Route.AccountStatus](),
  // routes[Route.SignUp](),
  routes[Route.ImportsBulkChangeEntry](),
  routes[Route.ImportsBulkChangeRequest](),
  new RegExp(
    routes[Route.ImportsBulkChangeFlow]({
      scheme: `(${BulkChangeSchemes.Ach}|${BulkChangeSchemes.Bacs}|${BulkChangeSchemes.Becs}|${BulkChangeSchemes.BecsNz}|${BulkChangeSchemes.Sepa})`,
    })
  ),
  new RegExp(routes[Route.Import]({ id: "[A-Z0-9]+" })),
];

/**
 * Additional routes recorded by FullStory when KCC experiment is enabled
 */
export const KccFullStoryAllowedRoutes: string[] = [routes[Route.Home]()];

export type RouteParams = Record<string, string | undefined>;

/**
 * Utility function to get the Route enum from a path.
 * @param path The path to match.
 * @returns The corresponding Route enum value or undefined if not found.
 */
export const getRouteFromPath = (path: string): Route | undefined => {
  if (path === "/") return Route.Home;
  const cleanPath = path.split(/[?#]/)[0];
  const normalizedPath = cleanPath?.endsWith("/")
    ? cleanPath.slice(0, -1)
    : cleanPath;

  let bestMatch: Route | undefined;
  let bestMatchScore = -1;

  for (const [route, pathPattern] of Object.entries(rawPaths)) {
    // Create two regex patterns: one for exact match and one for partial match
    const exactRegexPattern = pathToRegexp(pathPattern);
    const partialRegexPattern = pathToRegexp(pathPattern, [], { end: false });

    // Check for exact match first
    const exactMatch = exactRegexPattern.exec(normalizedPath as string);
    // If no exact match, check for partial match
    const partialMatch = exactMatch
      ? null
      : partialRegexPattern.exec(normalizedPath as string);

    if (exactMatch || partialMatch) {
      const patternSegments = pathPattern.split("/");
      let score =
        patternSegments.length * 1000 +
        patternSegments.filter((segment) => !segment.startsWith(":")).length;

      // Prefer exact matches over partial matches
      if (exactMatch) {
        score += 10000;
      }

      if (score > bestMatchScore) {
        bestMatch = route as Route;
        bestMatchScore = score;
      }
    }
  }

  return bestMatch;
};

export const getRoute = (route: Route, params?: RouteParams) =>
  routes[route](params);

export const isExternal = (route: Route) => externalRoutes.has(route);

export const matchesDynamicRoute = (
  pathname: string,
  dynamicRoutes: string[]
): boolean => dynamicRoutes.some((route) => pathToRegexp(route).test(pathname));
